<template>
    <div class="body-section planning__body-block flex-box flex-column">
        <div class="loading py-5" v-if="loading">
            <Loading />
        </div>
        <div id="dashboardContainer"></div>
    </div>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import Loading from '@/components/common/Loading';

export default {
    components: {
        Loading,
    },

    data() {
        return {
            loading: true,
        };
    },

    methods: {
        async getQuickSightDashboardEmbedURL() {
            const data = await Auth.currentSession();

            const params = {
                headers: {},
                response: true,
                queryStringParameters: {
                    jwtToken: data.idToken.jwtToken,
                    payloadSub: data.idToken.payload.sub,
                    email: data.idToken.payload.email,
                    username: data.idToken.payload['cognito:username'],
                },
            };

            const quicksight = await API.get('quicksight', '/getQuickSightDashboardEmbedURL', params);

            const options = {
                url: quicksight.data.data.EmbedUrl,
                container: '#dashboardContainer',
                parameters: {
                    user_name: data.idToken.payload['cognito:username'],
                    salesrep_id: data.idToken.payload['custom:sales_rep_id'],
                },
                height: 'AutoFit',
                loadingHeight: '700px',
                iframeResizeOnSheetChange: true,
                footerPaddingEnabled: true,
            };

            embedDashboard(options);
            this.loading = false;
        },
    },

    created() {
        this.getQuickSightDashboardEmbedURL();
    },
};
</script>
